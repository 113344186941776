"use client"

import { forwardRef, useEffect, useState, type ComponentProps, type MouseEvent, type Ref } from "react"
import Script from "next/script"
import { env } from "~/env"

import type { GID } from "@unlikelystudio/commerce-connector"

import type { ExplicitAny } from "~/@types/generics"
import { SHOPIFY_SHOP_ID } from "~/lib/shopify/constants"
import { getReferenceIdFromGID, isGID } from "~/lib/shopify/utils/id"
import SquareCta, { type SquareCtaProps } from "~/components/ui/SquareCta"

// Based on https://help.baback.co/fr/article/integration-baback-sur-shopify-headless-1nvg090/
declare global {
  interface Window {
    baback: ExplicitAny
  }
}

type BabackCtaProps = {
  orderId?: string | GID
} & Omit<SquareCtaProps, "onClick" | "href">

function BabackCta({ orderId, ...props }: BabackCtaProps) {
  const { BabackScript, onClick } = useBaback(orderId)

  return (
    <>
      <BabackScript />
      <SquareCta onClick={onClick} {...props} />
    </>
  )
}

export default BabackCta

export function useBaback(orderId?: string | GID) {
  const [canLoad, setCanLoad] = useState(false)
  const providerId = SHOPIFY_SHOP_ID[env.NEXT_PUBLIC_BRAND_NAME]

  useEffect(() => {
    if (!window.baback) {
      window.baback = { providerId }
      setCanLoad(true)
    } else {
      window.baback.providerId = providerId
      if (!window.baback?.initied) {
        setCanLoad(true)
      }
    }
  }, [])

  const sanitizedOrderId = isGID(orderId) ? getReferenceIdFromGID(orderId) : orderId

  const onClick = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    window.baback.openPopup(sanitizedOrderId ? { orderId: sanitizedOrderId } : {})
  }

  const BabackScript = () => {
    return canLoad && <Script src="https://cdn1.baback.co/index.js" type="module" async />
  }

  return {
    BabackScript,
    onClick,
  }
}

export const BabackLink = forwardRef(function (
  {
    orderId,
    ...props
  }: {
    orderId?: string | GID
  } & Omit<ComponentProps<"button">, "onClick" | "href">,
  ref: Ref<HTMLButtonElement>
) {
  const { BabackScript, onClick } = useBaback(orderId)

  return (
    <>
      <BabackScript />
      <button ref={ref} onClick={onClick} {...props} />
    </>
  )
})
