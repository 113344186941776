import { getMarket } from "~/lib/i18n/utils/get-i18n"
import useLocale from "~/hooks/useLocale"

function useMarket(): string {
  const locale = useLocale()

  return getMarket(locale)
}

export default useMarket
