import { z } from "zod"

import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"

// Get from <https://shopify.dev/api/usage/gids#global-id-examples>
const GlobalIDNames = [
  "Collection",
  "Customer",
  "DeliveryCarrierService",
  "DeliveryLocationGroup",
  "DeliveryProfile",
  "DeliveryZone",
  "DraftOrder",
  "DraftOrderLineItem",
  "Duty",
  "Fulfillment",
  "FulfillmentEvent",
  "FulfillmentService",
  "InventoryItem",
  "InventoryLevel",
  "LineItem",
  "Location",
  "MarketingEvent",
  "MediaImage",
  "Metafield",
  "OnlineStoreArticle",
  "OnlineStoreBlog",
  "OnlineStorePage",
  "Order",
  "OrderTransaction",
  "Product",
  "ProductImage",
  "ProductVariant",
  "Refund",
  "Shop",
  "StaffMember",
  "Theme",
  "Variant",
] as const

export type TGlobalIDName = (typeof GlobalIDNames)[number]

export const getPropertyFromGID = (id: string): TGlobalIDName | null => {
  const REGEX = /gid:\/\/shopify\/([a-zA-Z]+)\/\d+/
  const matches = id.match(REGEX)
  return matches?.[1] && GlobalIDNames.includes(matches[1]) ? (matches[1] as TGlobalIDName) : null
}

export function getGIDFromReferenceId(id: number, property: TGlobalIDName): GID | null {
  if (!id) return null
  return `gid://shopify/${property}/${id}`
}

export const getReferenceIdFromGID = (gid: string | undefined | null) => {
  if (!gid) {
    return null
  }

  const splitGid: string[] = gid.split("/")
  const id = splitGid[splitGid.length - 1]?.split("?")[0]
  return Number(id)
}

export const getReferenceIdFromGIDWithoutConvert = (gid: string | undefined | null) => {
  if (!gid) {
    return null
  }

  const splitGid: string[] = gid.split("/")
  const id = splitGid[splitGid.length - 1]?.split("?")[0]
  return id
}

export function sanitizeGid(id: GID): string | null {
  return id.split("?")[0] ?? null
}

export function isGID(value: Nullish<string>): value is GID {
  const regex = /^gid:\/\/shopify\/\w+\/\d+/
  return regex.test(value ?? "")
}

// decodeBase64 string utf8
export const getBase64CursorFromId = (id: Nullish<number>, value: number) => {
  if (!id) {
    return null
  }

  const parsedCursor = handleZodSafeParse(
    z.object({
      last_id: z.number(),
      last_value: z.number(),
    }),
    {
      last_id: id,
      last_value: value,
    }
  )

  const encodedCursor = parsedCursor ? Buffer.from(JSON.stringify(parsedCursor)).toString("base64") : null

  return encodedCursor
}
