import marketsConfig from "config/markets.json"

import i18nConfig from "~/config/i18n.json"
import type { Markets } from "~/lib/i18n/types"
import { objectEntries } from "~/utils/object-entries"
import { objectValues } from "~/utils/object-values"

export function getDefaultI18n() {
  const defaultI18n = objectValues(i18nConfig).find((store) => Boolean(store.default))

  if (!defaultI18n) {
    throw new Error("No default i18n found in i18n.json")
  }

  return defaultI18n
}

export function getDefaultLocale() {
  const defaultI18n = objectEntries(i18nConfig).find(([_key, store]) => Boolean(store.default))

  if (!defaultI18n?.length) {
    throw new Error("No default locale found in i18n.json")
  }

  return defaultI18n[0]
}

export function getDefaultCountry(defaultMarket: string) {
  const defaultCountry = marketsConfig[defaultMarket as Markets]?.[0]

  if (!defaultCountry) {
    throw new Error("No default country found in markets.json")
  }

  return defaultCountry
}
