import { getColorWithOpacity } from "~/utils/get-color-with-transparency"

const baseColors = {
  black: "#1C1C1C",
  white: "#ffffff",
  "yellow-dolly": "#E2B0A5",
  "grey-delta": "#A6A6A4",
  "grey-alto": "#D9D9D9",
  "grey-gallery": "#EBEBEB",
  "grey-darker-alabaster": "#F7F7F7",
  "grey-alabaster": "#FFF9F9",
  "grey-tundora": "#434343",
  "alert-red": "#F24537",
  beige: "#D0BDB8",
  cream: "#D7CAC7",
  brown: "#AE8880",
} as const

const computedColors = {
  "black/25": getColorWithOpacity(baseColors.black, 25),
  "black/30": getColorWithOpacity(baseColors.black, 30),
  "black/50": getColorWithOpacity(baseColors.black, 50),
  "black/70": getColorWithOpacity(baseColors.black, 70),
  "black/80": getColorWithOpacity(baseColors.black, 80),
  "grey-tundora/80": getColorWithOpacity(baseColors["grey-tundora"], 80),
  "grey-tundora/70": getColorWithOpacity(baseColors["grey-tundora"], 70),
  "grey-delta/60": getColorWithOpacity(baseColors["grey-delta"], 60),
  "grey-delta/40": getColorWithOpacity(baseColors["grey-delta"], 40),
} as const

export const colors = { ...baseColors, ...computedColors } as const
