export const MULTICOLOR_HEXA = "#multicolor"

export const COLORS = {
  BEIGE: "#E0CDA9",
  BLACK: "#000000",
  BLUE: "#0e4bef",
  BROWN: "#6e3300",
  CAMEL: "#c46620",
  DARK_BLUE: "#03224c",
  FUCHSIA: "#e11b72",
  GOLDEN: "#edd671",
  GRAY: "#c0c0c0",
  GREEN: "#008020",
  KHAKI: "#7a763d",
  MULTICOLOR: MULTICOLOR_HEXA,
  ORANGE: "#ff7f00",
  PINK: "#fec5d9 ",
  PURPLE: "#800080",
  RED: "#c60800",
  WHEAT: "#DCB253",
  WHITE: "#FFFFFF",
  YELLOW: "#FFFF99 ",
} satisfies Record<string, `#${string}`>

export const COLORS_VALUE = {
  Argent: COLORS.GRAY,
  Beige: COLORS.BEIGE,
  Black: COLORS.BLACK,
  Blanc: COLORS.WHITE,
  Blé: COLORS.WHEAT,
  Bleu: COLORS.BLUE,
  "Bleu foncé / Marine": COLORS.DARK_BLUE,
  Blue: COLORS.BLUE,
  Brown: COLORS.BROWN,
  camel: COLORS.CAMEL,
  Camel: COLORS.CAMEL,
  "Dark blue / Navy": COLORS.DARK_BLUE,
  Doré: COLORS.GOLDEN,
  Fuchsia: COLORS.FUCHSIA,
  Golden: COLORS.GOLDEN,
  Gray: COLORS.GRAY,
  Green: COLORS.GREEN,
  Grey: COLORS.GRAY,
  Gris: COLORS.GRAY,
  Jaune: COLORS.YELLOW,
  Kaki: COLORS.KHAKI,
  Khaki: COLORS.KHAKI,
  Marron: COLORS.BROWN,
  Multicolor: COLORS.MULTICOLOR,
  Multicolore: COLORS.MULTICOLOR,
  Noir: COLORS.BLACK,
  Orange: COLORS.ORANGE,
  Pink: COLORS.PINK,
  Purple: COLORS.PURPLE,
  Red: COLORS.RED,
  Rose: COLORS.PINK,
  Rouge: COLORS.RED,
  Vert: COLORS.GREEN,
  Violet: COLORS.PURPLE,
  Yellow: COLORS.YELLOW,
  Wheat: COLORS.WHEAT,
  White: COLORS.WHITE,
} satisfies Record<string, `#${string}`>

export const COLOR_LABELS = {
  Argent: "color_silver",
  Beige: "color_beige",
  Black: "color_black",
  Blanc: "color_white",
  Blé: "color_wheat",
  Bleu: "color_blue",
  "Bleu foncé / Marine": "color_navy",
  Blue: "color_blue",
  Brown: "color_brown",
  camel: "color_camel",
  Camel: "color_camel",
  "Dark blue / Navy": "color_navy",
  Doré: "color_gold",
  Fuchsia: "color_fuchsia",
  Golden: "color_gold",
  Gray: "color_grey",
  Green: "color_green",
  Grey: "color_grey",
  Gris: "color_grey",
  Jaune: "color_yellow",
  Kaki: "color_kaki",
  Khaki: "color_kaki",
  Marron: "color_brown",
  Multicolor: "color_multicolor",
  Multicolore: "color_multicolor",
  Noir: "color_black",
  Orange: "color_orange",
  Pink: "color_pink",
  Purple: "color_purple",
  Red: "color_red",
  Rose: "color_pink",
  Rouge: "color_red",
  Vert: "color_green",
  Violet: "color_purple",
  Yellow: "color_yellow",
  Wheat: "color_wheat",
  White: "color_white",
} as const
