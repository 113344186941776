"use client"

import AbstractSpinner, { type SpinnerProps as AbstractSpinnerProps } from "~/components/abstracts/Spinner"

import { colors } from "~/styles/variables/colors"

export type SpinnerProps = AbstractSpinnerProps

function Spinner(props: SpinnerProps) {
  return <AbstractSpinner color={colors["grey-tundora"]} size={15} {...props} />
}

export default Spinner
