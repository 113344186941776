import type { Env } from "~/env"

import type { UnlikelyPageInfo } from "@unlikelystudio/commerce-connector"

import { isDarel } from "~/lib/brand"
import { isVercelProduction } from "~/lib/vercel/constants"

export const SHOPIFY_SHOP_URL = "gerard-darel.myshopify.com"
export const CHECKOUT_URL = "checkout.gerarddarel.com"
export const DEFAULT_PAGE_INFO: UnlikelyPageInfo = {
  endCursor: null,
  startCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
}
export const COLLECTION_HITS_PER_PAGE = 48
export const SEARCH_HITS_PER_PAGE = 48

export const SHOPIFY_API_VERSION = "2023-10"
export const BRAND_NAME = isDarel ? "Gerard Darel" : "Pablo"

export const SHOPIFY_SHOP_ID = (
  isVercelProduction
    ? {
        pablo: 26949288020,
        "gerard-darel": 51761971381,
      }
    : {
        pablo: 87019225382,
        "gerard-darel": 78717714746,
      }
) satisfies Record<Env["NEXT_PUBLIC_BRAND_NAME"], number>

export const STOCK_LOW = 3

export * from "./colors"
export * from "./custom-attributes"
export * from "./image-shoot-type"
export * from "./metafields"
export * from "./selected-options"
