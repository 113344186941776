export const imageShootType = {
  Packshot: "packshot",
  American: "american",
  FullLength: "full-length",
} as const

export type ImageShootType = (typeof imageShootType)[keyof typeof imageShootType]

// Based on https://docs.google.com/presentation/d/1HExJEMdW-JkaTlF5Z0j6KbdAl5W_hiN3z_wuxhYVXjo/edit#slide=id.p1
export const imageShootTypeSuffix = {
  [imageShootType.Packshot]: "1",
  [imageShootType.American]: "5",
  [imageShootType.FullLength]: "e",
}
