import { env } from "~/env"

import isDev from "~/utils/is-dev"

export const isVercelProduction = env.NEXT_PUBLIC_VERCEL_ENV === "production"

export const isVercelPreview = env.NEXT_PUBLIC_VERCEL_ENV === "preview"

export const isVercelDevelopment = env.NEXT_PUBLIC_VERCEL_ENV === "development"

export const processedRevalidate = isDev ? 0 : isVercelProduction ? 3600 * 24 : 5
