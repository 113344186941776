"use client"

import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import { Link, type LinkProps } from "~/components/ui/Link"
import Spinner from "~/components/ui/Spinner"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type SquareCtaVariants = RecipeVariants<typeof css.button>

export type SquareCtaProps = LinkProps &
  SquareCtaVariants & {
    fill?: boolean
    isLoading?: boolean
    overrideTextPresets?: boolean
  }

function SquareCta({
  className,
  children,
  theme = "borderBlack",
  overrideTextPresets = false,
  state,
  fill,
  size = "default",
  isLoading = false,
  ...rest
}: SquareCtaProps) {
  return (
    <Link
      className={clsx(
        className,
        text,
        css.button({
          size,
          theme,
          state,
          defaultText: !overrideTextPresets,
          ...(fill ? { fillParent: "default" } : {}),
        })
      )}
      {...rest}
    >
      {(props) => (
        <>
          {isLoading && <Spinner className={clsx(css.spinner)} />}
          <span className={clsx(css.inner({ hidden: isLoading }))}>
            {typeof children === "function" ? children(props) : children}
          </span>
        </>
      )}
    </Link>
  )
}

export default SquareCta
