import type { Nullish } from "~/@types/generics"
import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { isLocale } from "~/lib/i18n/utils/is-locale"

export function getProcessedLocale(locale: Nullish<string> | string[]) {
  return (Array.isArray(locale) ? locale[0] : locale) ?? DEFAULT_LOCALE
}

export function geti18nLocale(locale: Nullish<string>) {
  return isLocale(locale) ? locale : DEFAULT_LOCALE
}
