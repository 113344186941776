import React from "react"
import { m, type MotionStyle } from "framer-motion"

export interface SpinnerProps extends React.SVGProps<SVGSVGElement> {
  children?: JSX.Element | null
  className?: string
  color?: string
  size?: number
  strokeWidth?: number
  width?: number
  withBackground?: boolean
}
function Spinner({ className, color = "#000000", size = 20, strokeWidth = 1, withBackground = false }: SpinnerProps) {
  const containerStyle: MotionStyle = { width: size, originX: "50%", originY: "50%" }
  const pathStyle: React.CSSProperties = {
    strokeLinecap: "round",
  }
  const midSize = size * 0.5

  const SpinnerBackground = () => (
    <circle
      cx={midSize}
      cy={midSize}
      r={midSize - 1}
      fill="none"
      strokeWidth={strokeWidth}
      strokeOpacity={0.5}
      stroke={color}
      style={pathStyle}
    ></circle>
  )

  return (
    <m.svg
      data-comp="Abstracts/Spinner"
      className={className}
      viewBox={`0 0 ${size} ${size}`}
      animate={{ rotate: 360 }}
      transition={{ repeat: Infinity, duration: 1.5, ease: "linear" }}
      style={containerStyle}
    >
      <m.circle
        cx={midSize}
        cy={midSize}
        r={midSize - 1}
        fill="none"
        strokeWidth={strokeWidth}
        stroke={color}
        style={pathStyle}
        animate={{
          strokeDasharray: [`1px ${size * 3}`, `${size * 2} ${size * 3}`, `${size * 2} ${size * 3}`],
          strokeDashoffset: [0, -size, size * -2.5],
        }}
        transition={{
          repeat: Infinity,
          duration: 1.5,
          ease: "linear",
        }}
      />
      {withBackground && SpinnerBackground()}
    </m.svg>
  )
}

export default Spinner
