import { captureException } from "@sentry/nextjs"

/**
 * @description This is a logger for internal usage
 * @please Use console for debugging
 *
 */
export const logger = new Proxy(console, {
  get(target, prop, receiver) {
    if (prop === "error") {
      return (...params: Parameters<(typeof console)["error"]>) => {
        console.error(...params)
        captureException(params)
      }
    }

    return Reflect.get(target, prop, receiver)
  },
})
