import { useParams } from "next/navigation"

import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"

function useLocale(): string {
  const { locale = DEFAULT_LOCALE } = useParams()

  return getProcessedLocale(locale)
}

export default useLocale
